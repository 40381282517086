import styled from 'styled-components';
import { Stack } from '@tymate/margaret';

export const FormField = styled(Stack).attrs({
  gutterSize: 0.25,
  size: 'full',
  alignX: 'stretch',
  direction: 'column',
})`
  position: relative;
  ${({ gridColumn }) =>
    Boolean(gridColumn) &&
    `
      grid-column: ${gridColumn};
    `}
`;

export const Input = styled.input`
  outline: none;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  line-height: 1;
  background-color: #ffffff;
  box-shadow: ${({ theme }) => theme.boxShadows.subtle};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius.subtle};
  min-height: 40px;
  display: flex;
  align-items: center;
  transition: border 150ms ease;

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.separatorDark};
  }
`;

export const Label = styled.label`
  ${({ theme }) => theme.fontStyles.bodySmall};
  font-weight: 600;
`;

export const ErrorMessageWrapper = styled.div`
  ${({ theme }) => theme.fontStyles.bodySmall};
  color: ${({ theme }) => theme.error};
  position: absolute;
  top: calc(100% + ${({ theme }) => theme.spacing(0.125)});
  line-height: 1;
`;

export const ErrorMessage = styled.div`
  ${({ theme }) => theme.fontStyles.bodySmall};
  color: ${({ theme }) => theme.error};
`;
