import React from 'react';
import { theme } from 'ui';
import { injectMargaret, MargaretProvider } from '@tymate/margaret';
import styled, { createGlobalStyle } from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import { Toaster } from 'react-hot-toast';
import '@fontsource/playfair-display/400.css';
import '@fontsource/playfair-display/700.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/700.css';
import 'sanitize.css';
import 'sanitize.css/typography.css';
import 'sanitize.css/forms.css';
import 'react-medium-image-zoom/dist/styles.css';

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  body {
    font-family:  ${({ theme }) => theme.fonts.body};
    color: ${({ theme }) => theme.text};
    line-height: 1.5;
  }

  img {
    display: block;
    max-width: 100%;
  }

  h1, h2 {
    font-family: "Playfair Display", serif;
    color: inherit;
  }

  .c-youtube-video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  a {
    color: #00a1f2;
  }
`;

const Site = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Hero = styled.div`
  position: relative;
  z-index: 0;
`;

const Main = styled.main`
  flex: 1;
  position: relative;
`;

const Layout = ({ children, variant, hero }) => (
  <MargaretProvider theme={injectMargaret(theme)}>
    <GlobalStyle />

    <Site>
      <Header variant={variant} />
      {Boolean(hero) && <Hero>{hero}</Hero>}
      <Main id="content">{children}</Main>
      <Footer />
    </Site>

    <Toaster position="bottom-center" />
  </MargaretProvider>
);

export default Layout;
