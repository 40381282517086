export const theme = {
  text: 'rgba(0, 0, 0, 0.87)',
  textLight: 'rgba(0, 0, 0, 0.6)',
  textLighter: 'rgba(0, 0, 0, 0.38)',

  textOnDark: '#ffffff',
  textOnDarkLight: 'rgba(255, 255, 255, 0.87)',
  textOnDarkLighter: 'rgba(255, 255, 255, 0.6)',

  separator: 'rgba(0, 0, 0, 0.12)',
  separatorDark: 'rgba(0, 0, 0, 0.24)',
  separatorSecondary: 'rgba(218, 176, 113, 0.38)',
  background: '#f4f4f4',

  primary: '#1e65b8',
  primaryLight: 'rgba(30, 101, 184, 0.24)',
  primaryDark: '#0D4F86',

  secondary: '#a39171',
  secondaryDark: '#b7945f',
  secondaryLight: 'hsl(36, 59%, 75%)',
  highlight: '#449df7',
  error: '#e0351b',
  success: '#29cc88',

  viewportSizes: {
    desktop: 1200,
    medium: 1000,
    tablet: 750,
  },

  button: {
    transition: 'background 150ms ease, box-shadow 150ms ease',
    boxShadowFocus: '0 0 0 4px rgba(68, 157, 247, 0.38)',
    primary: {
      borderRadius: '999px',
      background: '#a39171',
      color: '#ffffff',
      paddingHorizontal: '24px',
      paddingVertical: '12px',
      minHeight: '40px',
      backgroundHover: '#897a5f',
    },
    secondary: {
      borderRadius: '999px',
      background: 'rgba(255, 255, 255, 0.24)',
      color: '#ffffff',
      paddingHorizontal: '16px',
      paddingVertical: '8px',
      minHeight: '40px',
      backgroundHover: '#1e65b8',
      boxShadowFocus: '0 0 0 4px rgba(68, 157, 247, 0.38)',
      boxShadowHover: '0 4px 16px rgba(0, 0, 0, 0.38)',
    },
  },

  mainNav: {
    width: '320px',
    maxWidth: '100%',
    background: {
      mobile: '#1e65b8',
      tablet: 'transparent',
    },
    boxShadow: 'none',
    position: 'top',
    height: 'auto',
    calculatedHeight: '96px',
    triggerColor: {
      expanded: '#ffffff',
      collapsed: '#ffffff',
    },
  },

  borderRadius: {
    subtle: '4px',
    default: '6px',
  },

  boxShadows: {
    subtle: '0 1px 2px rgba(0, 0, 0, 0.08)',
    default: '0 2px 4px rgba(0, 0, 0, 0.12)',
  },

  fonts: {
    title:
      '"Playfair Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
    body:
      'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
  },

  fontStacks: {
    h1Mega: {
      sizeMinRem: 1.75,
      sizeMaxRem: 2.625,
      lineHeightMin: 1.2,
      lineHeightMax: 1.1,
      fontFamily: 'title',
      fontWeight: 400,
      textAlign: 'center',
      color: 'text',
    },
    h1: {
      sizeMinRem: 1.375,
      sizeMaxRem: 2,
      lineHeightMin: 1.25,
      lineHeightMax: 1.2,
      fontFamily: 'title',
      fontWeight: 400,
      textAlign: 'left',
      color: 'text',
    },
    h2: {
      sizeMinRem: 1.25,
      sizeMaxRem: 1.5,
      lineHeightMin: 1.25,
      lineHeightMax: 1.2,
      fontFamily: 'title',
      fontWeight: 400,
      textAlign: 'left',
      color: 'text',
    },
    heading: {
      sizeMinRem: 1.125,
      sizeMaxRem: 1.25,
      lineHeightMin: 1.4375,
      lineHeightMax: 1.25,
      fontFamily: 'body',
      fontWeight: 600,
      textAlign: 'left',
      color: 'text',
    },
    subHeading: {
      sizeMinRem: 1.0625,
      sizeMaxRem: 1.1875,
      lineHeightMin: 1.2,
      lineHeightMax: 1.3,
      fontFamily: 'body',
      fontWeight: 700,
      textAlign: 'left',
      color: 'text',
    },
    bodyLarge: {
      sizeMinRem: 1.0625,
      sizeMaxRem: 1.1875,
      lineHeightMin: 1.2,
      lineHeightMax: 1.3,
      fontFamily: 'body',
      fontWeight: 400,
      textAlign: 'left',
      color: 'text',
    },
    body: {
      sizeMinRem: 1,
      sizeMaxRem: 1,
      lineHeightMin: 1.3125,
      lineHeightMax: 1.5,
      fontFamily: 'body',
      fontWeight: 400,
      textAlign: 'left',
      color: 'text',
    },
    bodySmall: {
      sizeMinRem: 0.8125,
      sizeMaxRem: 0.875,
      lineHeightMin: 1.4,
      lineHeightMax: 1.35,
      fontFamily: 'body',
      fontWeight: 400,
      textAlign: 'left',
      color: 'text',
    },
  },
};
