import React from 'react';
import styled from 'styled-components';
import {
  Stack,
  media,
  MainNav as MgtMainNav,
  useMargaret,
  PopoverMenu,
  PopoverItemButton,
  Dropdown,
} from '@tymate/margaret';
import { Link, useStaticQuery, graphql } from 'gatsby';
import NavLinks from './NavLinks';
import { Pill } from 'ui';
import { MdArrowDropDown } from 'react-icons/md';
import { useTransform, motion, useViewportScroll } from 'framer-motion';
import Logo from 'components/Logo';

const MainNav = styled(MgtMainNav)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  position: fixed;

  ${media.desktop`
    padding-left: ${({ theme }) => theme.spacing(1.5)};
    padding-right: ${({ theme }) => theme.spacing(1.5)};
  `}
`;

const LogoLink = styled(Link)`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(2)};
  left: 50%;
  transform: translateX(-50%);
  display: block;

  ${media.tablet`
    position: static;
    transform: none;
  `}
`;

const Trigger = styled.div`
  position: absolute;
  top: 0;
  z-index: 2;
  color: ${({ theme }) => theme.primary};

  ${({ variant }) =>
    variant === 'hero' &&
    `
      color: ${({ theme }) => theme.textOnDark};
    `}

  ${media.tablet`
    display: none;
  `}

  ${({ isOpen }) =>
    isOpen &&
    `
      position: fixed;
    `}
`;

const HeaderChunk = styled(Stack)`
  margin-top: ${({ theme }) => theme.spacing(0.5)};

  ${media.tablet`
    margin-top: 0;
  `}

  ${media.desktop`
    width: 15rem;
    max-width: 25vw;
  `}
`;

const MainNavBackground = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: ${({ theme }) => theme.boxShadows.default};
`;

const Header = ({ variant }) => {
  const { mainNavIsExpanded, isMobile } = useMargaret();
  const { allDatoCmsRight, datoCmsGlobalInfo } = useStaticQuery(graphql`
    query header {
      datoCmsGlobalInfo {
        genapiLink
        paprworkLink
        logo {
          url
        }
      }
      allDatoCmsRight(sort: { fields: order }) {
        nodes {
          icon {
            url
          }
          slug
          name
          specialties {
            slug
          }
        }
      }
    }
  `);

  const { scrollY } = useViewportScroll();
  const scrollYRange = [0, 150, 250];
  const color = useTransform(scrollY, scrollYRange, [
    'rgba(255, 255, 255, 1)',
    'rgba(255, 255, 255, 1)',
    'rgba(0, 0, 0, 0.6)',
  ]);
  const logoSize = useTransform(scrollY, scrollYRange, ['4em', '4em', '3.5em']);
  const logoVerticalPadding = useTransform(scrollY, scrollYRange, [
    '0.5em',
    '0.5em',
    '0em',
  ]);
  const mainNavBackgroundOpacity = useTransform(scrollY, scrollYRange, [
    0,
    0,
    1,
  ]);

  return (
    <>
      <MainNav variant={variant}>
        <MainNavBackground style={{ opacity: mainNavBackgroundOpacity }} />

        <Stack
          direction={{ default: 'column', tablet: 'row' }}
          alignY="center"
          alignX="space-between"
          size="full"
          as={motion.div}
          style={{ color, zIndex: 1 }}
        >
          <HeaderChunk>
            <LogoLink to={`${process.env.GATSBY_SITE_URL}/`}>
              <motion.div
                style={{
                  width: logoSize,
                  color,
                  paddingTop: logoVerticalPadding,
                  paddingBottom: logoVerticalPadding,
                }}
              >
                <Logo />
              </motion.div>
            </LogoLink>
          </HeaderChunk>

          <NavLinks variant="header" allDatoCmsRight={allDatoCmsRight} />

          <HeaderChunk>
            {Boolean(datoCmsGlobalInfo?.genapiLink) && (
              <Stack
                size="full"
                alignX={{ default: 'flex-start', tablet: 'flex-end' }}
              >
                <Dropdown
                  trigger={
                    <Pill as="div" variant="dropdown">
                      <Stack
                        alignX="space-between"
                        gutterSize={0.5}
                        alignY="center"
                      >
                        <div>Mon espace client</div>
                        <Stack>
                          <MdArrowDropDown />
                        </Stack>
                      </Stack>
                    </Pill>
                  }
                >
                  <PopoverMenu alignment={isMobile ? 'left' : 'right'}>
                    {Boolean(datoCmsGlobalInfo?.paprworkLink) && (
                      <PopoverItemButton
                        as="a"
                        href={datoCmsGlobalInfo?.paprworkLink}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                      >
                        Mon espace avocat
                      </PopoverItemButton>
                    )}
                    {Boolean(datoCmsGlobalInfo?.genapiLink) && (
                      <PopoverItemButton
                        as="a"
                        href={datoCmsGlobalInfo?.genapiLink}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                      >
                        Mon espace notaire
                      </PopoverItemButton>
                    )}
                  </PopoverMenu>
                </Dropdown>
              </Stack>
            )}
          </HeaderChunk>
        </Stack>
      </MainNav>

      <Trigger variant={variant} isOpen={mainNavIsExpanded}>
        <MainNav.Trigger />
      </Trigger>
    </>
  );
};

export default Header;
