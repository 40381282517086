import styled, { css } from 'styled-components';
import {
  Box,
  Container as MgtContainer,
  media,
  ButtonReset,
  PillsNavBar as MgtPillsNavBar,
} from '@tymate/margaret';

export const MainTitle = styled(Box).attrs(
  ({ as, marginTop, marginBottom }) => ({
    as: as || 'h1',
    marginTop: marginTop === undefined ? 0 : marginTop,
    marginBottom: marginBottom === undefined ? 0 : marginTop,
  }),
)`
  ${({ theme }) => theme.fontStyles.h1Mega};
  font-weight: ${({ theme }) => theme.fontStacks.h1Mega.fontWeight};
  font-family: ${({ theme }) => theme.fonts.title};
  text-shadow: 0 4px 8px ${({ theme }) => theme.separator};

  ${({ variant }) =>
    variant === 'light' &&
    css`
      color: ${({ theme }) => theme.textOnDark};
    `}

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      color: ${({ theme }) => theme.primary};
      text-align: center;
    `}

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      color: ${({ theme }) => theme.textOnDark};
      font-weight: bold;
      text-align: center;
    `}

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const Title = styled(Box).attrs(({ as, marginTop, marginBottom }) => ({
  as: as || 'h2',
  marginTop: marginTop === undefined ? 0 : marginTop,
  marginBottom: marginBottom === undefined ? 0 : marginTop,
}))`
  ${({ theme }) => theme.fontStyles.h1};
  font-weight: ${({ theme }) => theme.fontStacks.h1.fontWeight};

  ${({ variant }) =>
    variant === 'light' &&
    css`
      color: ${({ theme }) => theme.textOnDark};
    `}

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      color: ${({ theme }) => theme.secondary};
    `}

  ${({ variant }) =>
    variant === 'tocable' &&
    css`
      padding-top: ${({ theme }) => theme.spacing(5)};
      margin-top: ${({ theme }) => theme.spacing(-5)};
    `}
`;

export const Subtitle = styled(Box).attrs(({ as }) => ({
  as: as || 'h3',
}))`
  ${({ theme }) => theme.fontStyles.h2};

  ${({ variant }) =>
    variant === 'light' &&
    css`
      color: ${({ theme }) => theme.textOnDark};
    `}

  ${({ variant }) =>
    variant === 'tocable' &&
    css`
      padding-top: ${({ theme }) => theme.spacing(5)};
      margin-top: ${({ theme }) => theme.spacing(-5)};
    `}
`;

export const Heading = styled(Box).attrs(({ as }) => ({
  as: as || 'h4',
}))`
  ${({ theme }) => theme.fontStyles.heading};
`;

export const Subheading = styled(Box).attrs(({ as }) => ({
  as: as || 'h5',
}))`
  ${({ theme }) => theme.fontStyles.heading};
`;

export const Kicker = styled(Box)`
  ${({ theme }) => theme.fontStyles.bodyLarge};
  font-weight: 600;
`;

export const Legend = styled(Box)`
  ${({ theme }) => theme.fontStyles.bodySmall};
  color: ${({ theme }) => theme.textLighter};
`;

export const CardsList = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1.5)};
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(2)};

  ${({ theme, variant }) =>
    variant === 'articles' &&
    theme.media.tablet`
      grid-template-columns: repeat(3, 1fr);
    `}

  ${({ variant }) =>
    variant === 'auto' &&
    `
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    `}
`;

export const Container = styled(MgtContainer)`
  ${({ variant, theme }) =>
    variant === 'main' &&
    `
      padding-top: ${theme.spacing(4)};
    `}

  ${({ variant }) =>
    variant === 'withAside' &&
    css`
      ${media.desktop`
        max-width: calc(100vw - 20rem - ${({ theme }) => theme.spacing(3)});
        transform: translateX(calc(-11.5rem));
      `}
    `}
`;

export const PictureLegend = styled(Box)`
  ${({ theme }) => theme.fontStyles.bodySmall}

  color: ${({ theme }) => theme.textLight};
  text-align: center;
`;

const pillStyles = css`
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  color: #ffffff;
  border: 1px solid currentColor;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  display: block;
  border-radius: 999px;
  transition: background-color 150ms ease;
  outline: none;

  &:hover,
  &.active {
    background-color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    box-shadow: 0 0 0 4px rgba(68, 157, 247, 0.38);
  }
`;

export const Pill = styled.li`
  &:before {
    display: none;
  }

  a,
  button {
    ${pillStyles}
  }

  ${({ variant }) => variant === 'dropdown' && pillStyles}

  ${({ variant }) =>
    variant === 'dropdown' &&
    css`
      color: inherit;
    `}
`;

export const DefaultLink = styled.a`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const RightsGrid = styled.div`
  display: grid;
  background-color: ${({ theme }) => theme.primaryDark};
  color: ${({ theme }) => theme.textOnDark};
  grid-auto-rows: 1fr;
  align-items: center;

  ${media.tablet`
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
  `}
`;

export const Decoration = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 15vw;
  z-index: 1;

  ${({ variant }) =>
    variant === 'bottom' &&
    `
      top: auto;
      bottom: 0;
    `}
`;

export const SingleInput = styled.input`
  padding: ${({ theme }) => theme.spacing(0.75)}
    ${({ theme }) => theme.spacing()};
  padding-right: ${({ theme }) => theme.spacing(3)};
  border-radius: 24px;
  border: solid 1px ${({ theme }) => theme.separator};
  background-color: #ffffff;
  outline: none;
  width: 20em;
  max-width: calc(100vw - ${({ theme }) => theme.spacing(2)});

  ${({ variant }) =>
    variant === 'search' &&
    css`
      width: 30em;
      padding-right: ${({ theme }) => theme.spacing()};
      padding-left: ${({ theme }) => theme.spacing(3)};
    `}
`;

export const SingleInputButton = styled(ButtonReset)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${({ theme }) => theme.spacing()};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.secondary};

  ${({ variant }) =>
    variant === 'search' &&
    css`
      left: ${({ theme }) => theme.spacing()};
      right: auto;
    `}
`;

export const PillsNavBar = styled(MgtPillsNavBar)`
  box-shadow: none;
  background-color: transparent;
  max-width: calc(100vw - ${({ theme }) => theme.spacing(2)});
  margin-left: auto;
  margin-right: auto;
  overflow-x: auto;
  overflow-y: visible;

  ${media.desktop`
    justify-content: flex-start;
    padding: ${({ theme }) => theme.spacing(0.875)} ${({ theme }) =>
    theme.spacing()};
  `}
`;
