import React from 'react';
import { Stack } from '@tymate/margaret';
import styled, { css } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Container } from 'ui';

const CoverWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(8, 28, 52, 0.6);
    z-index: 1;
    ${({ variant }) =>
      variant === 'bleeding' &&
      `
        bottom: -8em;
      `}
  }
`;

const CoverImage = styled(GatsbyImage)`
  height: 100%;

  ${({ variant }) =>
    variant === 'bleeding' &&
    `
      height: calc(100% + 8em);
    `}
`;

const Outter = styled(Stack).attrs({
  as: 'header',
  direction: 'column',
  alignX: 'center',
  alignY: 'center',
})`
  min-height: calc(40vh + 8em);
  flex: 1;

  ${({ variant }) =>
    variant === 'bleeding' &&
    css`
      min-height: 40vh;

      &:after {
        bottom: -8em;
      }
    `}
`;

const Inner = styled(Container)`
  position: relative;
  padding-top: ${({ theme }) => theme.mainNav.calculatedHeight};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const Hero = ({ cover, children, variant }) => (
  <Outter variant={variant}>
    {Boolean(cover) && (
      <CoverWrapper variant={variant}>
        <CoverImage image={cover?.gatsbyImageData} variant={variant} />
      </CoverWrapper>
    )}
    <Inner>{children}</Inner>
  </Outter>
);

export default Hero;
