import React, { useState } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Stack, media, List } from '@tymate/margaret';
import { Container, DefaultLink, Decoration } from 'ui';
import { FaLinkedin, FaTwitter } from 'react-icons/fa';
import { MdMail } from 'react-icons/md';
import decorationTopWhite from 'images/section-top-white.svg';

const Outter = styled(Box).attrs({ paddingVertical: 2, as: 'footer' })`
  ${({ theme }) => theme.fontStyles.bodySmall}
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.textOnDark};
  position: relative;
`;

const Inner = styled(Stack).attrs({ direction: 'column' })`
  ${({ theme }) => theme.fontStyles.bodySmall}
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.primary};
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing()};

  ${({ theme }) => theme.media.desktop`
    padding: ${({ theme }) => theme.spacing(2)}
    ${({ theme }) => theme.spacing(2.875)};
  `}
`;

const Name = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme }) => theme.textOnDark};
`;

const Contact = styled(Stack).attrs({ gutterSize: 0.5, direction: 'column' })`
  color: ${({ theme }) => theme.textOnDarkLight};
`;

const Logo = styled(Stack)`
  width: 6em;
  max-width: 100%;
`;

const Main = styled(Stack).attrs({ direction: 'column' })`
  flex: 1;
`;

const Address = styled.div`
  white-space: pre-wrap;
`;

const PhoneNumber = styled.a`
  color: inherit;
  text-decoration: none;
`;

const FooterTerms = styled(Box).attrs({ paddingTop: 1 })`
  position: relative;
  color: ${({ theme }) => theme.textOnDarkLighter};
  width: 100%;

  ${media.tablet`
    width: auto;
  `}

  &:before {
    content: '';
    position: absolute;
    display: block;
    border-bottom: 1px solid ${({ theme }) => theme.textOnDarkLight};
    top: 0;
    width: 100%;

    ${media.tablet`
      left: 50%;
      width: 28em;
      max-width: cacl(100vw - ${({ theme }) => theme.spacing(2)});
      transform: translateX(-50%);
    `}
  }

  > * + *:before {
    content: ' • ';
  }
`;

const FooterDesigned = styled(Box).attrs({ paddingTop: 1 })`
  position: relative;
  color: ${({ theme }) => theme.textOnDarkLighter};
  width: 100%;

  ${media.tablet`
    width: auto;
  `}
`;

const SocialNetworkLinks = styled(List).attrs({
  gutterSize: 1,
})`
  border-radius: 999px;
  color: ${({ theme }) => theme.textOnDarkLighter};
  border: 1px solid currentColor;
  padding: ${({ theme }) => theme.spacing(0.75)}
    ${({ theme }) => theme.spacing(1.5)};
`;

const SocialNetworkLink = styled.a`
  display: block;
  color: inherit;
  text-decoration: none;
  transition: color 150ms ease;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.textOnDark};
  }
`;

const Footer = () => {
  const [year] = useState(new Date().getFullYear());
  const { datoCmsGlobalInfo } = useStaticQuery(
    graphql`
      query footer {
        datoCmsGlobalInfo {
          logo {
            url
          }
          phoneNumber
          address
          name
          linkedinLink
          twitterLink
          mail
          terms {
            url
          }
          privacyPolicy {
            url
          }
        }
      }
    `,
  );

  return (
    <Outter>
      <Decoration src={decorationTopWhite} alt="" />
      <Container>
        <Inner gutterSize={2} alignX="center">
          <Stack
            direction={{ default: 'column', tablet: 'row' }}
            gutterSize={2}
            size="full"
          >
            <Logo>
              <img src={datoCmsGlobalInfo?.logo?.url} alt="" />
            </Logo>
            <Main gutterSize={0.25}>
              <Name>{datoCmsGlobalInfo.name}</Name>
              <Contact>
                <Address>{datoCmsGlobalInfo.address}</Address>
                <PhoneNumber
                  href={`tel:${(datoCmsGlobalInfo.phoneNumber || '').replace(
                    /\s/g,
                    '',
                  )}`}
                >
                  {datoCmsGlobalInfo.phoneNumber}
                </PhoneNumber>
              </Contact>
            </Main>
            {(Boolean(datoCmsGlobalInfo.linkedinLink) ||
              Boolean(datoCmsGlobalInfo.twitterLink) ||
              Boolean(datoCmsGlobalInfo.mailLink)) && (
              <SocialNetworkLinks>
                {Boolean(datoCmsGlobalInfo.linkedinLink) && (
                  <li>
                    <SocialNetworkLink
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                      href={datoCmsGlobalInfo.linkedinLink}
                    >
                      <FaLinkedin size={24} />
                    </SocialNetworkLink>
                  </li>
                )}
                {Boolean(datoCmsGlobalInfo.twitterLink) && (
                  <li>
                    <SocialNetworkLink
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                      href={datoCmsGlobalInfo.twitterLink}
                    >
                      <FaTwitter size={24} />
                    </SocialNetworkLink>
                  </li>
                )}
                {Boolean(datoCmsGlobalInfo.mail) && (
                  <li>
                    <SocialNetworkLink
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                      href={`mailto:${datoCmsGlobalInfo.mail}`}
                    >
                      <MdMail size={24} />
                    </SocialNetworkLink>
                  </li>
                )}
              </SocialNetworkLinks>
            )}
          </Stack>
          <Stack direction="column" alignX="center">
            <FooterTerms>
              <span>© Philaw {year}</span>
              {Boolean(datoCmsGlobalInfo.terms) && (
                <span>
                  <DefaultLink
                    href={datoCmsGlobalInfo.terms.url}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    Mentions légales
                  </DefaultLink>
                </span>
              )}

              {Boolean(datoCmsGlobalInfo.privacyPolicy) && (
                <span>
                  <DefaultLink
                    href={datoCmsGlobalInfo.privacyPolicy.url}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    Politique de confidentialité
                  </DefaultLink>
                </span>
              )}
            </FooterTerms>
            <FooterDesigned>
              <Stack size="full" direction="row">
                <span>
                  Conçu et développé par Tymate,{' '}
                  <DefaultLink
                    as="a"
                    target="_blank"
                    rel="noopener"
                    href="https://tymate.com"
                  >
                    Agence de conception et développement à Lille
                  </DefaultLink>
                </span>
              </Stack>
            </FooterDesigned>
          </Stack>
        </Inner>
      </Container>
    </Outter>
  );
};

export default Footer;
