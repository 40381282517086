import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { Stack, media, Button, useMargaret } from '@tymate/margaret';
import { MdKeyboardArrowRight } from 'react-icons/md';

const NavLinkItem = styled.li`
  display: flex;
  position: relative;

  ${media.desktop`
    border-right: 1px solid currentColor;
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  `}

  &:last-child {
    border-right: 0;
  }

  > div {
    display: none;
    position: absolute;
    border-radius: 0 4px 4px 4px;
    padding: ${({ theme }) => theme.spacing(0.5)}
      ${({ theme }) => theme.spacing()};
  }

  ${({ variant }) =>
    variant === 'withDropdown' &&
    css`
      &:hover > ${NavLink}, &:hover > div {
        color: ${({ theme }) => theme.textOnDark};
        display: flex;
        background-color: rgba(35, 42, 48, 0.81);
      }
    `}

  ${media.tablet`
    color: inherit;

    &.home-link {
      display: none;
    }
  `}

  .sublink {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: ${({ theme }) => theme.spacing(0.5)};
    }
  }
`;

const NavLink = styled(Link)`
  font-weight: 300;
  text-decoration: none;
  color: inherit;
  display: block;
  padding: ${({ theme }) => theme.spacing(0.5)} 0;
  border-radius: 4px 4px 0 0;

  ${media.tablet`
    padding-left: ${({ theme }) => theme.spacing()};
    padding-right: ${({ theme }) => theme.spacing()};
  `}
`;

const NavLinkPopover = styled(Stack).attrs({
  gutterSize: 0.5,
  direction: 'column',
  alignX: 'space-between',
})`
  top: 100%;
  z-index: 1;

  ${Button} {
    width: 100%;
  }
`;

const NavLinksBase = styled(Stack).attrs({
  gutterSize: { default: 0.5, tablet: 0 },
  alignX: { default: 'stretch', tablet: 'center' },
  direction: { default: 'column', tablet: 'row' },
  as: 'ul',
  padding: 0,
  margin: 0,
})`
  flex: 1;
  list-style-type: none;

  ${({ variant }) =>
    variant === 'header' &&
    css`
      padding-top: ${({ theme }) => theme.spacing(2)};

      ${media.tablet`
        padding-top: 0;  
      `}

      ${NavLink} {
        text-transform: uppercase;
        color: inherit;
        white-space: nowrap;

        ${media.tablet`
          &[aria-current="page"] {
            position: relative;

            &:after {
              position: absolute;
              left: 50%;
              top: calc(100% - ${({ theme }) => theme.spacing(0.25)});
              width: 6px;
              height: 6px;
              content: '';
              border-radius: 100%;
              background-color: currentColor;
              transform: translateX(-50%);
            }
          }
        `}
      }
    `}
`;

const Icon = styled.img`
  width: 2em;
  height: 2em;
`;

const NavLinks = ({ variant, allDatoCmsRight }) => {
  const { isMobile } = useMargaret();

  return (
    <NavLinksBase variant={variant}>
      <NavLinkItem className="home-link">
        <NavLink to={`${process.env.GATSBY_SITE_URL}/`}>Accueil</NavLink>
      </NavLinkItem>

      <NavLinkItem variant={!isMobile ? 'withDropdown' : undefined}>
        <NavLink
          to={`${process.env.GATSBY_SITE_URL}/expertises`}
          activeClassName="active"
          getProps={({ isPartiallyCurrent }) => ({
            'aria-current': isPartiallyCurrent ? 'page' : undefined,
          })}
        >
          Expertises
        </NavLink>
        {!isMobile && (
          <NavLinkPopover>
            {(allDatoCmsRight.nodes || []).map(
              ({ slug, icon, name, specialties }) => (
                <Button
                  variant="secondary"
                  key={slug}
                  as={Link}
                  to={`${process.env.GATSBY_SITE_URL}/expertises/${slug}/${
                    specialties?.[0]?.slug || ''
                  }`}
                  className="sublink"
                >
                  <Stack
                    alignY="center"
                    alignX="space-between"
                    gutterSize={0.5}
                  >
                    <Stack alignY="center" style={{ flex: 1 }}>
                      <Stack style={{ width: 48 }}>
                        <Icon src={icon?.url} alt="" />
                      </Stack>
                      <div css="width: 10em;">{name}</div>
                    </Stack>
                    <Stack>
                      <MdKeyboardArrowRight />
                    </Stack>
                  </Stack>
                </Button>
              ),
            )}
          </NavLinkPopover>
        )}
      </NavLinkItem>

      <NavLinkItem>
        <NavLink
          to={`${process.env.GATSBY_SITE_URL}/actualites`}
          activeClassName="active"
        >
          Actualités
        </NavLink>
      </NavLinkItem>

      <NavLinkItem>
        <NavLink
          to={`${process.env.GATSBY_NOTARIAL_SITE_URL}/office-notarial`}
          activeClassName="active"
        >
          L'office Notarial
        </NavLink>
      </NavLinkItem>

      <NavLinkItem>
        <NavLink
          to={`${process.env.GATSBY_SITE_URL}/contact`}
          activeClassName="active"
        >
          Contact
        </NavLink>
      </NavLinkItem>
    </NavLinksBase>
  );
};

export default NavLinks;
